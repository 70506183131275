/**
 * @ComponentFor ForgotPasswordPage
 */

import React from 'react';
import { styled } from '@glitz/react';
import ForgotPasswordPageType from './ForgotPasswordPage.type';
import { Part } from 'Shared/PageLayout/index';
import Input from 'Shared/Fields/Text';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import { pathCombine, postJson, translate, pushState, Breakpoint } from '@avensia/scope';
import { EpiProperty, epiPropertyValue } from '@avensia/scope-episerver';
import { ErrorMessage } from 'Shared/FeedbackMessage';
import { pixelsToUnit } from 'Shared/Style';
import AccountPanel from 'Account/Panel/AccountPanel';
import AccountForm from 'Account/Form/AccountForm';
import { emailPattern } from 'Shared/email-pattern';
import { minMediumMediaQuery } from 'Shared/Style';
import MainContainer from 'Shared/MainContainer';
import connect from 'Shared/connect';

type StateType = {
  email?: string;
  failedMessage?: string;
  successMessage?: string;
};

type ConnectStateType = {
  currentBreakpoint: number;
};

type RequiredProps = ForgotPasswordPageType;
type PropType = RequiredProps & ConnectPropType & ConnectStateType;

class ForgotPassword extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      email: '',
      failedMessage: '',
      successMessage: '',
    };
  }

  onSubmit = async () => {
    const isMobile = this.props.currentBreakpoint < Breakpoint.Medium;
    const isValidEmail = this.validateEmail();
    const email = this.state.email.trim();
    const url = pathCombine(location.pathname, 'SendResetPasswordLink');
    const query = { email };

    if (!email) {
      this.setFailMessage(translate('/Account/ForgotPassword/EmailRequiredField'));
    }

    if (email && isValidEmail) {
      try {
        const { success, message } = await postJson(url, query);
        if (success) {
          if (isMobile) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          }
          this.setSuccessMessage(epiPropertyValue(this.props.successMessage));
        } else {
          return Promise.reject(message);
        }
      } catch (error) {
        this.setFailMessage(error);
        return Promise.reject(null);
      }
    } else {
      return Promise.reject(null);
    }
  };

  setFailMessage(failedMessage: string) {
    this.setState({ failedMessage, successMessage: '' });
  }

  setSuccessMessage(successMessage: string) {
    this.setState({ successMessage, failedMessage: '' });
  }

  onEmailFieldInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      email: (e.target as HTMLInputElement).value,
      failedMessage: '',
      successMessage: '',
    });
  };

  validateEmail = () => {
    if (!!this.state.email && !emailPattern.test(this.state.email)) {
      this.setFailMessage(translate('/Account/ForgotPassword/InvalidEmail'));
      return false;
    }
    return true;
  };

  onLoginClick = async () => {
    if (!!epiPropertyValue(this.props.bottomLinkPage)) {
      return pushState(epiPropertyValue(this.props.bottomLinkPage).url);
    }
    return Promise.reject(null);
  };

  successSendEmailComponent() {
    return (
      <MainContainer>
        <AccountForm
          heading={epiPropertyValue(this.props.successTitle)}
          buttonCaption={epiPropertyValue(this.props.pageTitle)}
          buttonAction={this.onLoginClick}
        >
          <Part>{this.state.successMessage.replace('{{email}}', this.state.email)}</Part>
        </AccountForm>
      </MainContainer>
    );
  }

  render() {
    if (!!this.state.successMessage) {
      return this.successSendEmailComponent();
    }
    return (
      <MainContainer>
        <AccountForm
          heading={epiPropertyValue(this.props.pageTitle)}
          buttonCaption={epiPropertyValue(this.props.submitButtonCaption)}
          buttonAction={this.onSubmit}
          redirectLink={epiPropertyValue(this.props.bottomLinkPage).url}
          redirectLabel={epiPropertyValue(this.props.bottomLinkLabel)}
        >
          <PartDescription>
            <EpiProperty for={this.props.mainBody} component="div" />
          </PartDescription>
          <InputContainer>
            <InputLabel>{epiPropertyValue(this.props.emailLabel)}</InputLabel>
            <InputField
              required
              type="email"
              name="email"
              placeholder={epiPropertyValue(this.props.emailPlaceholder)}
              onChange={this.onEmailFieldInput}
              onBlur={this.validateEmail}
              value={this.state.email}
              invalid={!!this.state.failedMessage}
            />

            {!!this.state.failedMessage && <Error>{this.state.failedMessage}</Error>}
          </InputContainer>
        </AccountForm>
        <AccountPanel
          heading={epiPropertyValue(this.props.redirectButtonHeading)}
          redirectLink={epiPropertyValue(this.props.redirectButtonPage).url}
          buttonCaption={epiPropertyValue(this.props.redirectButtonCaption)}
        />
      </MainContainer>
    );
  }
}

const InputLabel = styled.label({
  marginLeft: pixelsToUnit(16),
});

const InputField = styled(Input, {
  paddingLeft: pixelsToUnit(16),
});

const InputContainer = styled.div({
  marginBottom: pixelsToUnit(40),
});

const Error = styled(ErrorMessage, {
  marginTop: pixelsToUnit(5),
  paddingLeft: pixelsToUnit(16),
});

const PartDescription = styled(Part, {
  textAlign: 'center',
  [minMediumMediaQuery]: {
    textAlign: 'left',
  },
});

export default connectWithFeedback()(
  connect(state => ({
    currentBreakpoint: state.currentBreakpoint,
  }))(ForgotPassword),
);
